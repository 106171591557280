@import 'variables';

.account-form-container {
    display: flex;
    flex: 1;
    flex-direction: column;

    .btn-container {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}

.profile-details {
    display: flex;
    flex: 1;
    flex-direction: column;

    .profile-item {
        border-bottom: 1px solid $divider-color;
        display: flex;
        font-family: $jakarta-font-family;
        font-size: $base-font-size;
        padding: 1.6rem 2.4rem;

        .label-container {
            display: flex;
            flex: 1;
            margin-right: 1.6rem;

            .label {
                align-items: center;
                color: $text-main-color;
                display: flex;
                font-weight: 500;
                line-height: 2.2rem;
            }
        }

        .value-container {
            align-items: center;
            display: flex;
            flex: 2;
            gap: 2.4rem;

            .value {
                color: $text-secondary-color;
                font-weight: 400;
                line-height: 2.2rem;
            }
        }
    }
}

.detele-account-section {
    .description {
        color: $text-main-color;
        font-family: $jakarta-font-family;
        font-size: $base-m-font-size;
        font-weight: 500;
        line-height: 2.5rem;
        margin-bottom: 2.4rem;
    }
}
