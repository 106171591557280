@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Open+Sans:wght@400;500;600;700&display=swap');


$button-height: 4rem;
$button-border-radius: 0.4rem;
$border-radius: 0.4rem;
$input-border-radius: 0.8rem;
$input-height: 5.6rem;
$padding-small: 0.8rem;
$padding-medium: 1.6rem;
$padding-large: 2.4rem;
$padding-xlarge: 3.2rem;
$card-padding: 3.2rem 2.4rem;
$card-default-gap: 2.4rem;

// font-families:
$inter-font-family: 'Inter', sans-serif;
$jakarta-font-family: 'Plus Jakarta Sans', sans-serif;

// font-sizes:
$xxs-font-size: 1rem;
$xs-font-size: 1.1rem;
$small-font-size: 1.2rem;
$small-base-font-size: 1.3rem;
$base-font-size: 1.4rem;
$base-ms-font-size: 1.5rem;
$base-m-font-size: 1.6rem;
$base-ml-font-size: 1.7rem;
$m-font-size: 1.8rem;
$ml-font-size: 2rem;
$l-font-size: 2.2rem;
$x-font-size: 2.4rem;
$xm-font-size: 2.5rem;
$xl-font-size: 2.6rem;
$xll-font-size: 2.8rem;
$xxl-font-size: 3.2rem;
$xxxl-font-size: 3.5rem;
$xxxxml-font-size: 4rem;
$xxxxl-font-size: 4.5rem;
$xxxxxl-font-size: 5rem;

// basic colors:
$white-color: #fff;
$black-color: #000;
$premium-color: #7744CC;
$theme-main-color: #149CBA;
$text-main-color: #111927;
$text-secondary-color: #6C737F;
$body-background-color: #FFFFFF;
$header-background-color: #F8F9FA;
$divider-color: #F2F4F7;
$gray-distinct-color: #E6E7E8;
$text-bright-color: #FFFFFF;
$input-border-color: #E9E9E9;
$info-color: #009fe3;
$auth-content-background-color: #1C2536;


//button colors:
$header-button-active-color: #EFF0F2;
$header-button-label: #848A94;
$primary-button-disabled-color: #a1d7e3;

// breakpoints:
$breakpoint-xsmall: 480px;
$breakpoint-small: 768px;
$breakpoint-medium: 992px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1280px;
$breakpoint-xxlarge: 1400px;
$breakpoint-full: 1600px;

// Body
$body-bg: #F8F9FA;
$body-color: #6e6b7b;

$alert-border-radius: 0.8rem;

// alert colors:
$alert-error-background-color: #FEE4E2;
$alert-error-color: #C83030;
$alert-error-icon-color: #7A271A;
$alert-success-background-color: #E8F8F5;
$alert-success-color: #08493f;
$alert-success-icon-color: #08493f;
$alert-warning-background-color: #fdead7;
$alert-warning-color: #772917;
$alert-warning-icon-color: #772917;
$alert-info-background-color: #CFF9FE;
$alert-info-color: #164C63;
$alert-info-icon-color: #164C63;
