@import './variables.scss';

.actions-container {
    background-color: $white-color;
    border-radius: $border-radius;
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
    padding: 1.2rem;

    .search-panel {
        display: flex;

        .reservations-search-form-container {
            display: flex;

            .reservation-search-form {
                flex-wrap: unset;
                gap: 1.2rem;

                .form-input.datepicker {
                    min-width: 22rem;
                }
            }
        }
    }
}

@media only screen and (max-width: $breakpoint-medium) {
    .actions-container {
        align-items: flex-start;
        flex-direction: column;

        .search-panel {
            .reservations-search-form-container {
                .reservation-search-form {
                    flex-wrap: wrap;
                }
            }
        }

    }
}
