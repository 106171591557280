@import "./variables";

.panel {
    background-color: $body-bg;
    min-height: 100vh;

    .main-container {
        display: flex;
        flex-direction: column;
        padding: $padding-large;

        .row {
            display: flex;

            .col-1 {
                flex: 1;
            }

            .col-2 {
                flex: 2;
            }
        }

        .header {
            align-items: center;
            border-bottom: 1px solid $divider-color;
            display: flex;
            gap: 2.4rem;
            justify-content: space-between;
            margin-bottom: 2.4rem;
            padding: 1.6rem 0;

            .title {
                color: $text-main-color;
                font-family: $jakarta-font-family;
                font-size: $xxl-font-size;
                font-weight: 700;
                line-height: 3.8rem;
            }

            .slug-container {
                align-items: center;
                background-color: $gray-distinct-color;
                border-radius: 1.2rem;
                display: flex;
                gap: 0.8rem;
                padding: 0.8rem 1.2rem;

                .slug-input {
                    background-color: transparent;
                    border: 0;
                    color: $text-main-color;
                    font-family: $inter-font-family;
                    font-size: $small-base-font-size;
                    width: auto;
                }

                .copy-button {
                    background-color: transparent;
                    border: 0;
                    border-radius: $border-radius;
                    cursor: pointer;
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 2.4rem;
        }
    }
}

@media only screen and (max-width: $breakpoint-medium) {
    .panel {
        .main-container {
            .row {
                flex-direction: column;
            }

            .header {
                align-items: flex-start;
                flex-direction: column;
            }

            .content {
                flex-direction: column;
            }
        }
    }
}
