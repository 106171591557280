@import 'variables';

.custom-card {
    background-color: $white-color;
    display: flex;
    gap: 2.4rem;
    margin-bottom: 2.4rem;
    padding: 3.2rem 2.4rem;

    .card-title-container {
        display: flex;
        flex: 1;

        .card-title {
            color: $text-main-color;
            font-family: $jakarta-font-family;
            font-size: $m-font-size;
            font-weight: 700;
            line-height: 2.1rem;
        }
    }

    .card-content-container {
        display: flex;
        flex: 3;
    }
}

@media only screen and (max-width: $breakpoint-medium) {
    .custom-card {
        flex-direction: column;
    }
}