@import './variables.scss';

.premium-badge {
    align-items: center;
    background: $premium-color;
    border-radius: 0.2rem;
    box-shadow: 0 0.01rem 0.02rem 0 rgba($black-color, 0.08);
    color: $white-color;
    display: flex;
    font-size: $small-font-size;
    font-weight: 600;
    gap: 0.6rem;
    padding: 0.8rem 1.6rem;
    text-decoration: none;
}