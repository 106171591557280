@import './variables.scss';

.navbar {
    align-items: center;
    background: $body-bg;
    border-bottom: 0.2rem solid $gray-distinct-color;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem 2.4rem;

    .app-details {
        display: flex;

        .logo {
            height: 4rem;
            margin-right: 1.6rem;
            width: 4rem;
        }

        .app-name {
            color: $text-main-color;
            font-family: $jakarta-font-family;
            font-size: $m-font-size;
            font-weight: 700;

            .title {
                line-height: 2.1rem;
            }

            .subtitle {
                color: $text-secondary-color;
                font-size: $small-font-size;
                font-weight: 500;
                line-height: 2rem;
            }
        }
    }

    .navbar-right {
        align-items: center;
        display: flex;
        gap: 2.4rem;

        .navbar-dropdown {
            position: relative;
            z-index: 2;

            .dropdown-toggle {
                color: $text-main-color;
                cursor: pointer;
            }

            .dropdown-menu {
                background: $body-background-color;
                border: 0.2rem solid #E9E9E9;
                border-radius: 0.2rem;
                display: flex;
                flex-direction: column;
                padding: 0.6rem 1.2rem;
                position: absolute;
                right: 0;
                top: calc(100% + 1rem);

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    .dropdown-menu-item {
                        .dropdown-menu-link {
                            align-items: center;
                            border-radius: 0.2rem;
                            color: $header-button-label;
                            display: flex;
                            gap: 1.2rem;
                            padding: 1.2rem;
                            text-decoration: none;

                            &.active {
                                background: $input-border-color;
                                cursor: not-allowed;
                            }
                        }

                        .dropdown-menu-divider {
                            background-color: $divider-color;
                            display: block;
                            height: 0.2rem;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    ul {
        li {
            display: inline;
            font-family: $jakarta-font-family;
            font-size: $base-font-size;
            font-style: normal;
            font-weight: 600;
            line-height: 2rem;


            .navbar-link {
                align-items: center;
                border-radius: 0.2rem;
                color: $header-button-label;
                display: flex;
                gap: 0.6rem;
                padding: 0.8rem 1.6rem;
                text-decoration: none;

                &.active {
                    background: $input-border-color;
                }
            }
        }
    }
}