

.form-container {
    &.open-hours-form {
        flex-direction: column;
        gap: 0;
    }
}

.restaurant-form-wrapper {
    margin-bottom: 4.8rem;

    .footer {
        background: $body-background-color;
        border-top: 0.2rem solid $input-border-color;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        left: 0;
        padding: 1.2rem;
        position: fixed;
        right: 0;
    }
}