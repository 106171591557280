@import "variables";

.btn-primary {
    align-items: center;
    background-color: $theme-main-color;
    border-radius: 0.8rem;
    box-sizing: border-box;
    color: $white-color;
    display: flex;
    font-family: $jakarta-font-family;
    font-size: $base-font-size;
    font-weight: 500;
    height: 4rem;
    padding: 0.8rem 1.6rem;

    &.btn-save {
        padding-left: 3.4rem;
        position: relative;

        &::after {
            background-image: url("../images/plus_icon.png");
            background-position: center;
            background-repeat: no-repeat;
            content: "";
            display: flex;
            height: 1.8rem;
            left: 0.8rem;
            margin-right: 0.8rem;
            position: absolute;
            width: 1.8rem;
        }
    }


    &:hover {
        cursor: pointer;
    }

    &:disabled {
        background-color: $primary-button-disabled-color;
    }
}

.btn-primary-outline {
    background-color: $white-color;
    border: 0.2 solid $theme-main-color;
    border-radius: 0.8rem;
    box-sizing: border-box;
    color: $theme-main-color;
    font-size: $small-font-size;
    font-weight: 500;
    height: 4rem;
    padding: 0.8rem 1.6rem;

    &:hover {
        cursor: pointer;
    }
}

.btn-back {
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    color: $text-main-color;
    display: flex;
    font-family: $jakarta-font-family;
    font-size: $base-font-size;
    font-weight: 500;
    height: 3.6rem;
    justify-content: center;
    padding: 0.8rem;

    .btn-icon {
        background: no-repeat url("../images/arrow_back.png");
        background-position: center;
        display: flex;
        height: 1.8rem;
        margin-right: 0.8rem;
        width: 1.8rem;

        &.icon-print {
            background: no-repeat url("../images/printer.png");
        }

        &.plus-icon {
            background: no-repeat url("../images/plus_icon_dark.png");
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.btn-premium {
    background: $premium-color;
    border-radius: 0.2rem;
    box-shadow: 0 0.1rem 0.2rem 0 rgba($black-color, 0.08);
    color: $white-color;
    display: flex;
    font-family: $jakarta-font-family;
    font-size: $small-font-size;
    font-weight: 600;
    letter-spacing: 0.02rem;
    line-height: 1.6rem;
    padding: 0.8rem 1.6rem;

    .icon-rocket {
        background: no-repeat url("../images/rocket_icon.png");
        background-position: center;
        display: flex;
        height: 1.8rem;
        margin-right: 0.4rem;
        width: 1.8rem;
    }

    &:hover {
        cursor: pointer;
        opacity: 0.4;
    }
}

.btn-icon {
    background-color: transparent;
    border: none;
    height: 2.4rem;
    position: relative;
    width: 2.4rem;

    &::after {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &:hover {
        cursor: pointer;
    }
}

.btn-delete {
    align-items: center;
    background-color: $white-color;
    border: 1px solid $alert-error-color;
    border-radius: 0.4rem;
    color: $alert-error-color;
    display: flex;
    font-family: $jakarta-font-family;
    font-size: $base-font-size;
    font-weight: 600;
    justify-content: center;
    line-height: 2rem;
    padding: 0.8rem 1.6rem;

    .icon-delete {
        background: no-repeat url("../images/trash_icon.png");
        background-position: center;
        display: flex;
        height: 1.8rem;
        margin-right: 0.8rem;
        width: 1.8rem;
    }

    &:hover {
        cursor: pointer;
        opacity: 0.4;
    }
}