@import './variables.scss';

.no-data-info {
    color: $text-secondary-color;
    font-family: $inter-font-family;
    font-size: $base-font-size;
    margin-top: 2rem;
    text-align: center;
}

.room-list {
    border: 1px solid $divider-color;
    border-radius: 0.4rem;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;

    .room-item {
        border-bottom: 1px solid $divider-color;
        display: flex;
        justify-content: space-between;
        padding: $padding-medium;

        &:last-child {
            border-bottom: none;
        }

        .room-item-details {
            color: $text-main-color;
            font-family: $inter-font-family;
            font-size: $base-font-size;
        }

        .room-price {
            color: $theme-main-color;
            font-family: $inter-font-family;
            font-size: $base-font-size;
            font-weight: 700;
        }
    }
}

.reservations-table {
    background: $white-color;
    border-collapse: collapse;
    border-radius: 2rem;
    box-shadow: 0 0 0 0.05rem rgba($black-color, 0.03), 0 0.5rem 2.2rem 0 rgba($black-color, 0.04);
    overflow: hidden;
    width: 100%;

    th, td {
        padding: $padding-medium;
        text-align: left;
    }

    th {
        background-color: $body-bg;
        color: $text-main-color;
        font-family: $jakarta-font-family;
        font-size: $small-font-size;
        font-weight: 600;
        letter-spacing: 0.05rem;
        line-height: 1.2rem;
        padding: 1.6rem;
        text-transform: uppercase;
    }

    tbody {
        tr {
            border-bottom: 1px solid $divider-color;

            td {
                color: $text-main-color;
                font-family: $jakarta-font-family;
                font-size: $base-font-size;
                font-weight: 400;
                line-height: 2.2rem;
                padding: 1.6rem 1.2rem;
            }
        }
    }
}

.pagination-container {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}

.custom-pagination {
    align-items: center;
    display: flex;
    gap: 0.75rem;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        &.active a {
            background-color: $premium-color;
            border-color: $premium-color;
            color: $white-color;
        }

        &.disabled a {
            opacity: 0.6;
            pointer-events: none;
        }

        &.prev-link, &.next-link {
            a {
                align-items: center;
                background-color: $header-background-color;
                border: 1px solid $input-border-color;
                border-radius: 1rem;
                display: flex;
                height: 3rem;
                justify-content: center;
                padding: 0;
                transition: background-color 0.3s ease, input-border-color 0.3s ease;
                width: 3rem;

                &:hover {
                    background-color: $header-button-label;
                    border-color: $header-button-active-color;
                }
            }
        }

        &.break-me a {
            color: $header-button-active-color;
            pointer-events: none;
        }
    }

    a {
        background-color: $header-background-color;
        border: 1px solid $input-border-color;
        border-radius: 0.5rem;
        color: $text-main-color;
        cursor: pointer;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        text-decoration: none;
        transition: background-color 0.3s ease, input-border-color 0.3s ease;
        
        &:hover {
            background-color: $header-button-label;
            border-color: $header-button-active-color;
        }
    }
}